import React, {Component} from "react"
import ReactDOM from 'react-dom'
// import {loadReCaptcha, ReCaptcha} from 'recaptcha-v3-react'
import Layout from "components/Layout/Layout"
import styled from "styled-components";
import {Link, navigate} from 'gatsby'
import Amplify, {API} from 'aws-amplify'
// import awsconfig from '../../aws-exports.js'
import awsconfig from '../../aws-lked-exports.js'
import {media} from "utils/Media"
import Hippodrome from "images/LK-Home-1linelogo-lyceum-1.svg"
import Cookies from "js-cookie"
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input
} from 'reactstrap'
import {
  validateRequired,
  validateEmail,
  validatePhone,
  validateAmount,
  validateRequiredCheckbox
} from 'js/validations'
import iconDropDown from "images/icons/icon-drop-down.svg"
// import ApplicationOver from "../../components/shared/ApplicationOver";

// const captchaSiteKey = "6Ld1Xa4UAAAAAAWXlgrYmeqKku8qw2apDa3jKpuZ"
const captchaSiteKey = "6LcEtecnAAAAAKZCE8Ry_JYadH3KKfJD54ox_jne"

Amplify.configure(awsconfig)

const HeaderWrapper = styled.div`
  margin: 0 auto 2rem;
  max-width: 1600px;
  width: 100%;
  background-color: ${props => props.theme.colors.yellow};
  text-align: center;
  padding: 0;
  position: relative;
  z-index: 2;

  @media ${media.md} {
    text-align: left;
  }

  .container {
    position: relative;
  }


`

const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  padding: 1rem 0;

  @media ${media.md} {
    flex-direction: unset;
    padding: 0;
    flex-wrap: nowrap;
  }

  .items {
    width: 100%;
    @media ${media.md} {
      width: 50%;
    }
  }

  a {
    margin: 0 auto;
    display: block;

    @media ${media.md} {
      text-align: right;
    }
  }

  img {
    width: 190px;

    @media ${media.md} {
      width: 320px;
    }
  }

  h1 {
    margin: 0 auto;
    font-family: ${props => props.theme.font.family.bold};
    color: ${props => props.theme.colors.brown};

    @media (max-width: 767px) {
      font-size: 28px;
    }
  }

  p {
    margin: 0 auto;
    line-height: 100%;
    color: ${props => props.theme.colors.brown};
    @media (max-width: 767px) {
      font-size: 0.875rem;
    }
  }
`

const FormTheme = styled.div`
  label {
    font-size: 1.125rem;
    font-family: ${props => props.theme.font.family.light};
    width: 100%;
  }

  input:not([type="checkbox"]), select, textarea {
    background-color: ${props => props.theme.colors.superLightGrey};
    width: 100%;
    border-radius: 0;
    padding: 0 1rem;
    min-height: 3rem;
  }

  select {
    background-image: url(${iconDropDown});
    background-position: calc(100% - 0.625rem) center;
    background-size: 15px;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .invalid-feedback {
    color: ${props => props.theme.colors.red};
    font-family: ${props => props.theme.font.family.base};
    font-size: 0.875rem !important;
    padding: 12px;
  }

  .red {
    color: ${props => props.theme.colors.red};
  }

  a {
    color: ${props => props.theme.colors.red};
  }

  .form-check:not(.no-border) {
    border: 1px solid ${props => props.theme.colors.grey};
    padding: 1rem;
    margin-bottom: 1rem;

    label {
      margin-left: 3rem;
    }

    input {
      left: -1rem;
    }
  }

  .form-check-input {
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
  }

  label.form-check-label.checkbox-btn {
    display: block;
    position: relative;
    padding-left: 10px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`

const SubmitButton = styled.button`
  width: 198px;
  height: 50px;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 200%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  color: ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.yellow};
  transition: all 150ms ease-in-out;

  &:hover {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.black};
  }
`

class EducationWorkshopForm extends Component {
  
  constructor(props) {
    super(props)
    
    this.state = {
      form: "bristol",
      nameOfSchool: '',
      postcode: '',
      borough: '',
      fullName: '',
      city: 'Bristol',
      positionAtSchool: '',
      email: '',
      phoneNumber: '',
      alternativeContactName: '',
      alternativeContactNumber: '',
      firstPreferredDate: '',
      secondPreferredDate: '',
      numberOfTickets: '',
      numberOfStudentTickets: '',
      numberOfTeacherTickets: '',
      yearGroup: '',
      freeSchoolMeals: '',
      pupilPremium: '',
      pupilsWhoNever: '',
      anyAccess: '',
      accessRequirements: '',
      performingArts: '',
      howToImpact: '',
      headTeacherReviewed: '',
      marketing: '',
      googleVerified: '',
      
      // nameOfSchool: "Rock",
      // postcode: "W1W 6XX",
      // borough: "London",
      // fullName: "David",
      // city: 'London',
      // positionAtSchool: "Pupil",
      // email: "d.simon@dewynters.com",
      // phoneNumber: "+447415287832",
      // alternativeContactName: "Tom",
      // alternativeContactNumber: "07777777777",
      // firstPreferredDate: "12",
      // secondPreferredDate: "18",
      // numberOfTickets: "35",
      // numberOfStudentTickets: "30",
      // numberOfTeacherTickets: "5",
      // yearGroup: "18",
      // freeSchoolMeals: "99",
      // pupilPremium: "50",
      // pupilsWhoNever: "50",
      // accessRequirements: "We don't need that",
      // performingArts: "Dunno",
      // howToImpact: "Not sure",
      // headTeacherReviewed: true,
      // marketing: true,
      // googleVerified: '',
      validate: {
        nameOfSchool: '',
        postcode: '',
        borough: '',
        fullName: '',
        city: 'London',
        positionAtSchool: '',
        email: '',
        emailErrMsg: 'A valid email is required',
        phoneNumber: '',
        phoneErrMsg: 'A valid UK phone number is required',
        alternativeContactName: '',
        alternativeContactNumber: '',
        alternativeContactNumberErrMsg: 'A valid UK phone number is required',
        firstPreferredDate: '',
        secondPreferredDate: '',
        numberOfTickets: '',
        numberOfTicketsErrMsg: 'Total number of tickets field is required',
        numberOfStudentTickets: '',
        numberOfStudentTicketsErrMsg: 'How many student tickets do you require field is required',
        numberOfTeacherTickets: '',
        numberOfTeacherTicketsErrMsg: 'How many teacher tickets do you require field is required',
        yearGroup: '',
        freeSchoolMeals: '',
        pupilPremium: '',
        pupilsWhoNever: '',
        anyAccess: '',
        accessRequirements: '',
        performingArts: '',
        howToImpact: '',
        headTeacherReviewed: '',
        marketing: '',
        googleVerified: '',
        googleVerifiedErrMsg: 'Google recaptcha is required'
      },
      success: false,
      cookieAccepted: true
    }
    
    // Bind this to methods
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onGoogleVerify = this.onGoogleVerify.bind(this)
    
    // Bind this to validation methods
    this.validateRequired = validateRequired.bind(this);
    this.validateEmail = validateEmail.bind(this);
    this.validatePhone = validatePhone.bind(this);
    this.validateAmount = validateAmount.bind(this);
    this.validateRequiredCheckbox = validateRequiredCheckbox.bind(this);
    
    // Create form ref
    this.form = React.createRef();
  }
  
  componentDidMount() {
    // if (
    //   Cookies.get('TWDC-DTCI_ENSIGHTEN_PRIVACY_TWDC_Vendor_Consent') !== '1'
    // ) {
    //   this.setState({
    //     cookieAccepted: true,
    //   })
    // }

    // if (
    //   Cookies.get('TWDC-DTCI_ENSIGHTEN_PRIVACY_TWDC_Vendor_Consent') === '1'
    // ) {
      const script = document.createElement('script')
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${captchaSiteKey}`
      script.addEventListener('load', () => {
        console.log('window.grecaptcha: loaded')
        const that = this
        if (
          typeof window.grecaptcha !== 'undefined'
          // Cookies.get('TWDC-DTCI_ENSIGHTEN_PRIVACY_TWDC_Vendor_Consent') === '1'
        ) {
          window.grecaptcha.enterprise.ready(() => {
            window.grecaptcha.enterprise
              .execute(captchaSiteKey, { action: 'sunriseScheme' })
              .then(token => {
                // console.log(token)
                that.onGoogleVerify(token)
              })
            // refresh token every minute to prevent expiration
            setInterval(() => {
              window.grecaptcha.enterprise
                .execute(captchaSiteKey, { action: 'sunriseScheme' })
                .then(token => {
                  // console.log('refreshed token:', token)
                  that.onGoogleVerify(token)
                })
            }, 60000)
          })
        }
      })
      document.body.appendChild(script)
    // }
  }
  
  handleChangeNumbers = (evt, stateName, stateValue) => {
    const value = (evt.target.validity.valid) ? evt.target.value : stateValue;
    
    this.setState({[stateName]: value});
  }
  
  async onGoogleVerify(response) {
    console.log('GR')
    const {validate} = this.state;
    const myInit = {
      headers: {},
      body: {
        recaptchaResponse: response,
      },
    };
    
    const res = await API.post("LKEducationAPI", "/LKRecaptcha", myInit)
      .then((response) => {
        // If there are errors update validation state
        console.log(response)
        if (response.event.success === false || response.event < 0.5) {
          validate.googleVerified = 'has-danger'
          console.log('FAILED')
          this.setState({
            validate,
            googleVerified: false
          });
          throw Error('Failed recaptcha');
        } else {
          //success
          console.log('SUCCESS')
          validate.googleVerified = ''
          this.setState({
            validate,
            googleVerified: true
          })
        }
      })
      .catch((error) => {
        console.log('caught error', error);
        // handle errors and timeout error
        validate.googleVerified = 'has-danger'
        this.setState({
          validate,
          googleVerified: false
        });
      });
  }
  
  // Method to update field values in state on change
  handleChange(e) {
    const target = e.target;
    const name = target.name
    const value = target.type === 'checkbox' ? target.checked : target.value;
    
    // Update value in state
    this.setState({
      [name]: value,
    });
  }
  
  // Method to handle form submit
  async handleSubmit(e) {
    e.preventDefault();
    
    const {validate} = this.state;
    
    let formData = {};
    for (let key in this.state) {
      formData[key] = this.state[key];
    }
    
    const myInit = {
      body: {
        formData
      },
    };
    
    const res = await API.post('LKEducationAPI', '/LKSunriseScheme', myInit)
      .then((response) => {
        console.log(response)
        const event = response.event
        
        // If there are errors update validation state
        if (event.errors !== false && event.errors !== undefined) {
          // Scroll top top of form
          const domNode = ReactDOM.findDOMNode(this.form.current)
          window.scrollTo({
            top: domNode.offsetTop,
            behavior: 'smooth'
          })
          
          const {validate} = this.state
          
          for (let key in event.errors) {
            if (event.errors[key] === true) {
              validate[key] = 'has-danger'
            } else {
              validate[key] = event.errors[key]
            }
            
            this.setState({validate});
          }
          
          //re-enable the button
          this.setState({
            sendingFormRequest: false,
            submitBtnCopy: 'Submit'
          });
          
        } else {
          if (event.success === true) {
            this.setState({
              success: true,
              submitBtnCopy: 'Sending form'
            });
          }
          if (typeof event.MessageId !== 'undefined') {
            navigate('/thank-you');
          } else {
            console.log(event.code)
          }
          // navigate('/thank-you');
        }
        
        //re-enable the button
        this.setState({
          sendingFormRequest: false,
          submitBtnCopy: 'Submit'
        });
        
        // Scroll top top of form
        const domNode = ReactDOM.findDOMNode(this.form.current)
        window.scrollTo({
          top: domNode.offsetTop,
          behavior: 'smooth'
        })
      })
  }
  
  openDialog = (e) => {
    if (typeof window !== 'undefined' && typeof window.__dataLayer !== 'undefined') {
      window.__dataLayer.OneTrustDialog(e)
    }
  }
  
  render() {

    // console.log(this.state.validate.googleVerified)

    return (
      <Layout slug="sunrise-scheme" title="Our First Show Application Form">
        <HeaderWrapper>
          <Container fluid={true}>
            <Container>
              <HeaderContent>
                <div className="items">
                  <h1>OUR FIRST SHOW</h1>
                  <p>Making live theatre accessible to young people and schools</p>
                </div>
                <Link className="items" to="/"><img className="pb-2" src={Hippodrome}
                                                    alt="The Lion King - Bristol Hippodrome"/></Link>
              </HeaderContent>
            </Container>
          </Container>
        </HeaderWrapper>
         {/* <ApplicationOver/>  */}
        <FormTheme>
           <Container fluid={true}>
               <Container>
                   <>
                       <h2 className="mb-3">Our First Show Application Form</h2>
                       <Row>
                           <Col xs={12} md={6}>
                           <p>Welcome to the Our First Show Scheme for Disney's THE LION KING that enables school groups to purchase a limited number of £10 tickets to the West End musical on select performances. To take part in the scheme, fill in and return the application form before Friday 22nd September.</p>
<p>Do this by filling in the online form below.</p>
<p>The Our First Show Scheme supports cultural engagement within schools by giving pupils who may not otherwise be able the chance to experience a live theatre production.</p>

                           </Col>
        
                           <Col xs={12} md={6}>
                               <p><strong>Key information you need to know</strong></p>
        
                               <ul>
                                   <li>Tickets are £10 each</li>
                                   <li>A maximum of 70 tickets can be allocated per school/youth group, this includes teachers/leaders</li>
                                   <li>The scheme is open to primary/secondary schools and youth groups</li>
                                   <li>You can choose from the dates below</li>
                                   <li>The closing date for applications is Friday 22nd September</li>
                                   <li>You will be contacted if you have been successful with your application by Monday 25th September</li>
                                   <li>Successful applicants must pay by Monday 16th October</li>
        
                               </ul>
                           </Col>
                       </Row>
        
                       <Form onSubmit={(e) => this.handleSubmit(e)} noValidate ref={this.form}>
                           <Row>
                               <Col md={6}>
                                   <FormGroup>
                                       <Label for="name">Name of school:*</Label>
                                       <Input
                                            type="text"
                                            name="nameOfSchool"
                                            id="nameOfSchool"
                                            value={this.state.nameOfSchool}
                                            valid={this.state.validate.nameOfSchool === 'has-success'}
                                            invalid={this.state.validate.nameOfSchool === 'has-danger'}
                                            onChange={e => {
                                                this.handleChange(e)
                                                this.validateRequired(e)
                                            }}
                                        />
                                        <FormFeedback>
                                            Name of school is required
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="postcode">Postcode:*</Label>
                                        <Input
                                            type="text"
                                            name="postcode"
                                            id="postcode"
                                            value={this.state.postcode}
                                            valid={this.state.validate.postcode === 'has-success'}
                                            invalid={this.state.validate.postcode === 'has-danger'}
                                            onChange={e => {
                                                this.handleChange(e)
                                                this.validateRequired(e)
                                            }}
                                        />
                                        <FormFeedback>
                                            Postcode is required
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>

                            </Row>
        
                            <Row>
                                <Col md={6}>
                                  <FormGroup>
                                      <Label for="borough">Borough:*</Label>
                                      <Input
                                          type="text"
                                          name="borough"
                                          id="borough"
                                          value={this.state.borough}
                                          valid={this.state.validate.borough === 'has-success'}
                                          invalid={this.state.validate.postcboroughode === 'has-danger'}
                                          onChange={e => {
                                              this.handleChange(e)
                                              this.validateRequired(e)
                                          }}
                                      />
                                      <FormFeedback>
                                        Borough is required
                                      </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="name">Full Name:*</Label>
                                        <Input
                                            type="text"
                                            name="fullName"
                                            id="fullName"
                                            value={this.state.fullName}
                                            valid={this.state.validate.fullName === 'has-success'}
                                            invalid={this.state.validate.fullName === 'has-danger'}
                                            onChange={e => {
                                                this.handleChange(e)
                                                this.validateRequired(e)
                                            }}
                                        />
                                        <FormFeedback>
                                            Full name is required
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="name">Position at school:*</Label>
                                        <Input
                                            type="text"
                                            name="positionAtSchool"
                                            id="positionAtSchool"
                                            value={this.state.positionAtSchool}
                                            valid={this.state.validate.positionAtSchool === 'has-success'}
                                            invalid={this.state.validate.positionAtSchool === 'has-danger'}
                                            onChange={e => {
                                                this.handleChange(e)
                                                this.validateRequired(e)
                                            }}
                                        />
                                        <FormFeedback>
                                            Position at school is required
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="email">Email:*</Label>
                                        <Input
                                            type="email"
                                            name="email"
                                            id="email"
                                            value={this.state.email}
                                            valid={this.state.validate.email === 'has-success'}
                                            invalid={this.state.validate.email === 'has-danger'}
                                            onChange={e => {
                                                this.handleChange(e)
                                                this.validateRequired(e)
                                                this.validateEmail(e)
                                            }}
                                        />
                                        <FormFeedback>
                                            {this.state.validate.emailErrMsg}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="pb-5">
                            <Col md={6}>
                                    <FormGroup>
                                        <Label for="name">Phone number*</Label>
                                        <Input
                                            type="text"
                                            name="phoneNumber"
                                            id="phoneNumber"
                                            value={this.state.phoneNumber}
                                            valid={this.state.validate.phoneNumber === 'has-success'}
                                            invalid={this.state.validate.phoneNumber === 'has-danger'}
                                            onChange={e => {
                                                this.handleChange(e)
                                                this.validateRequired(e)
                                                this.validatePhone(e, 'phoneNumber', true)
                                            }}
                                        />
                                        <FormFeedback>
                                            {this.state.validate.phoneErrMsg}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="alternativeContactName">Alternative contact name</Label>
                                        <Input
                                            type="text"
                                            name="alternativeContactName"
                                            id="alternativeContactName"
                                            value={this.state.alternativeContactName}
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="alternativeContactNumber">Alternative contact number</Label>
                                        <Input
                                            type="text"
                                            name="alternativeContactNumber"
                                            id="alternativeContactNumber"
                                            value={this.state.alternativeContactNumber}
                                            valid={this.state.validate.alternativeContactNumber === 'has-success'}
                                            invalid={this.state.validate.alternativeContactNumber === 'has-danger'}
                                            onChange={e => {
                                                this.handleChange(e)
                                                this.validatePhone(e, 'alternativeContactNumber')
                                            }}
                                        />
                                        <FormFeedback>
                                            {this.state.validate.alternativeContactNumberErrMsg}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
        
                            <h2 className="mb-3">Please select your dates in order of 1 and 2, with 1 being the
                                most preferred option.</h2>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="firstPreferredDate">My first preferred date*</Label>
                                        <Input
                                            type="select"
                                            name="firstPreferredDate"
                                            id="firstPreferredDate"
                                            value={this.state.firstPreferredDate}
                                            valid={this.state.validate.firstPreferredDate === 'has-success'}
                                            invalid={this.state.validate.firstPreferredDate === 'has-danger'}
                                            onChange={e => {
                                                this.handleChange(e)
                                                this.validateRequired(e)
                                            }}
                                        >
                                            <option value="">Select</option>
                                            <option value="Tue 7th November 7.30pm">Tue 7th November 7.30pm</option>
                                            <option value="Wed 8th November 2.30pm">Wed 8th November 2.30pm</option>
                                            <option value="Wed 8th November 7.30pm">Wed 8th November 7.30pm</option>
                                            <option value="Tue 14th November 7.30pm">Tue 14th November 7.30pm</option>
                                            <option value="Wed 15th November 7.30pm">Wed 15th November 7.30pm</option>
                                            <option value="Thu 16th November 7.30pm">Thu 16th November 7.30pm</option>
                                            <option value="Tue 21st November 7.30pm">Tue 21st November 7.30pm</option>
                                            <option value="Wed 22nd November 2.30pm">Wed 22nd November 2.30pm</option>
                                            <option value="Wed 22nd November 7.30pm">Wed 22nd November 7.30pm</option>
                                            <option value="Thu 23rd November 7.30pm">Thu 23rd November 7.30pm</option>
                                            <option value="Tue 28th November 7.30pm">Tue 28th November 7.30pm</option>
                                            <option value="Wed 29th November 7.30pm">Wed 29th November 7.30pm</option>
                                            <option value="Thu 30th November 7.30pm">Thu 30th November 7.30pm</option>
                                        </Input>
                                        <FormFeedback>
                                            First preferred date is required
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="secondPreferredDate">My second preferred date*</Label>
                                        <Input
                                            type="select"
                                            name="secondPreferredDate"
                                            id="secondPreferredDate"
                                            value={this.state.secondPreferredDate}
                                            valid={this.state.validate.secondPreferredDate === 'has-success'}
                                            invalid={this.state.validate.secondPreferredDate === 'has-danger'}
                                            onChange={e => {
                                                this.handleChange(e)
                                                this.validateRequired(e)
                                            }}
                                        >
                                            <option value="">Select</option>
                                            <option value="Tue 7th November 7.30pm">Tue 7th November 7.30pm</option>
                                            <option value="Wed 8th November 2.30pm">Wed 8th November 2.30pm</option>
                                            <option value="Wed 8th November 7.30pm">Wed 8th November 7.30pm</option>
                                            <option value="Tue 14th November 7.30pm">Tue 14th November 7.30pm</option>
                                            <option value="Wed 15th November 7.30pm">Wed 15th November 7.30pm</option>
                                            <option value="Thu 16th November 7.30pm">Thu 16th November 7.30pm</option>
                                            <option value="Tue 21st November 7.30pm">Tue 21st November 7.30pm</option>
                                            <option value="Wed 22nd November 2.30pm">Wed 22nd November 2.30pm</option>
                                            <option value="Wed 22nd November 7.30pm">Wed 22nd November 7.30pm</option>
                                            <option value="Thu 23rd November 7.30pm">Thu 23rd November 7.30pm</option>
                                            <option value="Tue 28th November 7.30pm">Tue 28th November 7.30pm</option>
                                            <option value="Wed 29th November 7.30pm">Wed 29th November 7.30pm</option>
                                            <option value="Thu 30th November 7.30pm">Thu 30th November 7.30pm</option>
                                        </Input>
                                        <FormFeedback>
                                            Second preferred date is required
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
        
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="numberOfTickets">Total number of tickets required* (Max
                                            70)</Label>
                                        <Input
                                            type="text"
                                            pattern="[0-9]*"
                                            name="numberOfTickets"
                                            id="numberOfTickets"
                                            onInput={e => this.handleChangeNumbers(e, 'numberOfTickets', this.state.numberOfTickets)}
                                            value={this.state.numberOfTickets}
                                            valid={this.state.validate.numberOfTickets === 'has-success'}
                                            invalid={this.state.validate.numberOfTickets === 'has-danger'}
                                            onChange={e => {
                                                this.validateAmount(e, 'numberOfTickets', 'numberOfTicketsErrMsg', 70, 'Total number of tickets field is required')
                                            }}
                                        />
                                        <FormFeedback>
                                            {this.state.validate.numberOfTicketsErrMsg}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="numberOfStudentTickets">How many student tickets do you
                                            require* (Max 60)</Label>
                                        <Input
                                            type="text"
                                            pattern="[0-9]*"
                                            name="numberOfStudentTickets"
                                            id="numberOfStudentTickets"
                                            onInput={e => this.handleChangeNumbers(e, 'numberOfStudentTickets', this.state.numberOfStudentTickets)}
                                            value={this.state.numberOfStudentTickets}
                                            valid={this.state.validate.numberOfStudentTickets === 'has-success'}
                                            invalid={this.state.validate.numberOfStudentTickets === 'has-danger'}
                                            onChange={e => {
                                                this.validateAmount(e, 'numberOfStudentTickets', 'numberOfStudentTicketsErrMsg', 60, 'How many student tickets do you require field is required')
                                            }}
                                        />
                                        <FormFeedback>
                                            {this.state.validate.numberOfStudentTicketsErrMsg}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
        
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="numberOfTeacherTickets">How many teacher tickets do you
                                            require* (Max 10)</Label>
                                        <Input
                                            type="text"
                                            pattern="[0-9]*"
                                            name="numberOfTeacherTickets"
                                            id="numberOfTeacherTickets"
                                            onInput={e => this.handleChangeNumbers(e, 'numberOfTeacherTickets', this.state.numberOfTeacherTickets)}
                                            value={this.state.numberOfTeacherTickets}
                                            valid={this.state.validate.numberOfTeacherTickets === 'has-success'}
                                            invalid={this.state.validate.numberOfTeacherTickets === 'has-danger'}
                                            onChange={e => {
                                                this.validateAmount(e, 'numberOfTeacherTickets', 'numberOfTeacherTicketsErrMsg', 10, 'How many teacher tickets do you require field is required')
                                            }}
                                        />
                                        <FormFeedback>
                                            {this.state.validate.numberOfTeacherTicketsErrMsg}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                </Col>
                            </Row>
        
                            <Row className="mt-5">
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="yearGroup">Year group*</Label>
                                        <Input
                                            type="text"
                                            name="yearGroup"
                                            id="yearGroup"
                                            value={this.state.yearGroup}
                                            valid={this.state.validate.yearGroup === 'has-success'}
                                            invalid={this.state.validate.yearGroup === 'has-danger'}
                                            onChange={e => {
                                                this.handleChange(e)
                                                this.validateRequired(e)
                                            }}
                                        />
                                        <FormFeedback>
                                            Year group is required
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="freeSchoolMeals">Free school meals, in percentage*</Label>
                                        <Input
                                            type="text"
                                            name="freeSchoolMeals"
                                            id="freeSchoolMeals"
                                            value={this.state.freeSchoolMeals}
                                            valid={this.state.validate.freeSchoolMeals === 'has-success'}
                                            invalid={this.state.validate.freeSchoolMeals === 'has-danger'}
                                            onChange={e => {
                                                this.handleChange(e)
                                                this.validateRequired(e)
                                            }}
                                        />
                                        <FormFeedback>
                                            Free school meals field is required
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
        
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="pupilPremium">Pupil premium, in percentage*</Label>
                                        <Input
                                            type="text"
                                            name="pupilPremium"
                                            id="pupilPremium"
                                            value={this.state.pupilPremium}
                                            valid={this.state.validate.pupilPremium === 'has-success'}
                                            invalid={this.state.validate.pupilPremium === 'has-danger'}
                                            onChange={e => {
                                                this.handleChange(e)
                                                this.validateRequired(e)
                                            }}
                                        />
                                        <FormFeedback>
                                            Pupil premium field is required
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="pupilsWhoNever">Pupils who have never been to the theatre,
                                            in percentage*</Label>
                                        <Input
                                            type="text"
                                            name="pupilsWhoNever"
                                            id="pupilsWhoNever"
                                            value={this.state.pupilsWhoNever}
                                            valid={this.state.validate.pupilsWhoNever === 'has-success'}
                                            invalid={this.state.validate.pupilsWhoNever === 'has-danger'}
                                            onChange={e => {
                                                this.handleChange(e)
                                                this.validateRequired(e)
                                            }}
                                        />
                                        <FormFeedback>
                                            Pupils who have never been to the theatre field is required
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
        
                            <Row>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="anyAccess">Does your group have any access requirements?*</Label>
                                        <Input
                                            type="select"
                                            name="anyAccess"
                                            id="anyAccess"
                                            value={this.state.anyAccess}
                                            valid={this.state.validate.anyAccess === 'has-success'}
                                            invalid={this.state.validate.anyAccess === 'has-danger'}
                                            onChange={e => {
                                                this.handleChange(e)
                                                this.validateRequired(e)
                                            }}
                                        >
                                            <option value="">Select</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </Input>
                                        {/* <Input
                                            type="text"
                                            name="anyAccess"
                                            id="anyAccess"
                                            value={this.state.anyAccess}
                                            valid={this.state.validate.anyAccess === 'has-success'}
                                            invalid={this.state.validate.anyAccess === 'has-danger'}
                                            rows="5"
                                            onChange={e => {
                                                this.handleChange(e)
                                                this.validateRequired(e)
                                            }}
                                        /> */}
                                        <FormFeedback>
                                            Access requirements field is required
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
        
                            <Row>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="accessRequirements">If answered yes, please specify all access requirements below e.g. wheelchair users (transferable/non-transferable), step free access etc. If answered no, please write N/A.*</Label>
                                        <Input
                                            type="textarea"
                                            name="accessRequirements"
                                            id="accessRequirements"
                                            value={this.state.accessRequirements}
                                            valid={this.state.validate.accessRequirements === 'has-success'}
                                            invalid={this.state.validate.accessRequirements === 'has-danger'}
                                            rows="5"
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
        
                            <Row>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="performingArts">Why is it important for your students to
                                            have access to the performing arts? (Max 500 characters)</Label>
                                        <Input
                                            type="textarea"
                                            name="performingArts"
                                            id="performingArts"
                                            value={this.state.performingArts}
                                            valid={this.state.validate.performingArts === 'has-success'}
                                            invalid={this.state.validate.performingArts === 'has-danger'}
                                            rows="5"
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
        
                            <Row>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="howToImpact">Beyond an opportunity of bringing your students to see Disney’s THE LION KING, how would this project impact your school? Enhance the curriculum, arts award, arts mark etc (Max 500
                                            characters)</Label>
                                        <Input
                                            type="textarea"
                                            name="howToImpact"
                                            id="howToImpact"
                                            value={this.state.howToImpact}
                                            valid={this.state.validate.howToImpact === 'has-success'}
                                            invalid={this.state.validate.howToImpact === 'has-danger'}
                                            rows="5"
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
        
                            <Row className="pb-5">
                                <Col md={6}>
                                    <FormGroup className="no-border" check>
                                        <Label check className="font-normal font-weight-light checkbox-btn">
                                            <p>My head teacher has reviewed and is supportive of this
                                                application / I am the head teacher*</p>
                                            <Input
                                                type="checkbox"
                                                name="headTeacherReviewed"
                                                id="headTeacherReviewed"
                                                value={this.state.headTeacherReviewed}
                                                valid={this.state.validate.headTeacherReviewed === 'has-success'}
                                                invalid={this.state.validate.headTeacherReviewed === 'has-danger'}
                                                onChange={e => {
                                                    this.handleChange(e)
                                                    this.validateRequiredCheckbox(e)
                                                }}
                                            />
                                            <FormFeedback>
                                                Head teacher review is required
                                            </FormFeedback>
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <p>The information provided in this application form will be subject to Disney's Privacy Policy and will be used solely for the purpose of assessing your application.</p>
                                    <p>Disclaimer: by applying for these tickets I am committing to being able to bring a group on one of my preferred dates.</p>
                                </Col>
                            </Row>
                            <Row className="mb-4 mt-2">
                                <Col md={4}>
                                    <SubmitButton
                                        className="g-recaptcha btn--red btn--block"
                                        data-sitekey={captchaSiteKey}
                                        data-action='submit'
                                        onClick={(e) => this.handleSubmit(e)}
                                    >Submit</SubmitButton>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <p><strong>Terms & Conditions</strong></p>
        
                                    <p>Maximum number of tickets allowed at the special rate per school is 70, any additional tickets will be at the standard education rate (£22.50/£29.50) or the off-peak education rate (£19.50/£25). One application allowed per school (per term). All tickets are £10 irrespective of whether they are for teachers or pupils. The price of £10 per ticket is inclusive of a reduced theatre levy of 75p for this offer. Please following Government guidance of teacher to pupil ratios when booking tickets.</p>
                                    <p>The closing date for applications is Friday 22nd September. All successful schools will be contacted by Monday 25th September. Regrettably we cannot contact unsuccessful schools. Payment should be made by Monday 16th October.</p>
        
                                    <p>Please email any queries to <a
                                        href="mailto:theatreeducationuk@disney.co.uk">theatreeducationuk@disney.co.uk</a> ©Disney.<br/>
                                        <a href="https://privacy.thewaltdisneycompany.com/en/current-privacy-policy/"
                                           target="_blank"
                                           rel="noreferrer noopener">https://privacy.thewaltdisneycompany.com/en/current-privacy-policy/</a>
                                    </p>
                                </Col>
                            </Row>
                        </Form>
                        {/* <ReCaptcha
                            sitekey={captchaSiteKey}
                            action='educationWorkshopForm'
                            verifyCallback={this.onGoogleVerify}
                       /> */}
                   </>
               </Container>
           </Container>
        </FormTheme>
      </Layout>
    )
  }
  
}

export default EducationWorkshopForm

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "eu-west-2",
  "aws_cloud_logic_custom": [
      {
          "name": "LKEducationAPI",
          "endpoint": "https://api.lionkingeducation.co.uk",
          "region": "eu-west-2"
      },
  ]
};


export default awsmobile;